import React from 'react';
import {Create} from 'react-admin';
import {ModelForm} from './_Form';

export const CreateModel = (props) => (
  <Create {...props} undoable={false}>
    <ModelForm {...props}/>
  </Create>
);

