import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  ArrayField,
  Datagrid,
  useNotify,
} from 'react-admin'
import { MainAlgos } from './MainAlgos'
import imageNotFound from '../../assets/not-found.jpg'
import { BASE_TARGET } from '../../constants'

export const ShowCompany = props => {
  const notify = useNotify()

  const getOverrideFieldValue = data => {
    const overrideUserPoolValue = data.overrideUserPool

    switch (overrideUserPoolValue) {
      case 'nicehash':
        return 'nicehash'

      case 'viabtc':
        return 'viabtc'

      case 'HNP':
        return 'HNP'

      case 'HPOOL:7896':
        return 'HPOOL:7896'

      case 'HPOOL:4568':
        return 'HPOOL:4568'

      case 'HPOOL:8080':
        return 'HPOOL:8080'

      case 'HPOOL:443':
        return 'HPOOL:443'

      case null:
        return 'null'

      default:
        notify(`Nicehash has an invalid value "${overrideUserPoolValue}"`, {
          type: 'warning',
        })
        return <h4>Invalid nicehash value ({overrideUserPoolValue})</h4>
    }
  }

  const getTuneOnFlyValue = data => (data.tuneOnFly ? 'Tune_Fly' : 'Tune_old')

  return (
    <Show {...props} title='Company'>
      <SimpleShowLayout>
        <TextField source='originId' label='ID' />
        <TextField source='name' label='Name' />
        <TextField source='prefix' label='Prefix' />
        <TextField source='country' label='Country' />
        <MainAlgos />
        <NumberField source='ourFee' label='fee' />
        <ReferenceArrayField label='Allowed models' reference='models' source='allowedModels'>
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label='Allowed builds' reference='build_types' source='allowedBuildTypes'>
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source='feePools' label='Pools'>
          <Datagrid>
            <TextField source='ourPool.name' label='Our pool' />
            <TextField source='ourPool.host' label='Host' />
            <TextField source='worker' label='Worker' />
            <TextField source='password' label='Password' />
            <NumberField source='fee' label='Fee' />
          </Datagrid>
        </ArrayField>
        <FunctionField
          label='Logo'
          source='logo'
          render={file => (
            <img
              src={file.logo ? `${BASE_TARGET}/uploads/${file.logo.path}` : imageNotFound}
              style={{ maxWidth: '250px' }}
              alt='logo'
            />
          )}
        />
        <FunctionField label='nicehash' source='overrideUserPool' render={record => getOverrideFieldValue(record)} />
        <FunctionField label='tuneOnFly' source='tuneOnFly' render={record => getTuneOnFlyValue(record)} />
      </SimpleShowLayout>
    </Show>
  )
}
