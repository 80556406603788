import React, {useEffect,useState} from 'react'

import {
    SimpleForm,
    SelectArrayInput,
    TextInput,
    email,
    required,
    SimpleFormIterator,
    SelectInput,
    ReferenceInput,
    usePermissions
} from 'react-admin'
import { UserRoles, GenerateChoices } from './ListUser'

export const UserForm = (props) => {
    const {permissions} = usePermissions()

    const [isEdit, setIsEdit] = useState(false)
    useEffect(() => {
        if(props.match.path === '/users/:id'){
            setIsEdit(true)
            return
        }

        setIsEdit(false)
    },[props.match.path])

    return (
        <SimpleForm {...props}>
            <TextInput source="email" label="Email" validate={[email(), required()]} />
            <TextInput
                disabled={isEdit}
                validate={required()}
                source="username"
                label="Username"
            />
            <TextInput source="plainPassword" label="Password" validate={!isEdit?required():null}/>
            {
                (permissions?.includes('ROLE_ADMIN') || permissions?.includes('ROLE_SUPER_ADMIN')) && 
                <ReferenceInput source="company" format={v => v ? v['@id'] : v} label="Company" reference="companies" validate={[required()]}>
                    <SelectInput optionText="name" validate={[required()]}/>
                </ReferenceInput>
            }
            { permissions?.includes('ROLE_SUPER_ADMIN') &&
                <SelectArrayInput
                    validate={required()}
                    source="roles"
                    label="Roles"
                    choices={GenerateChoices(UserRoles)}
                >
                    <SimpleFormIterator>
                        <TextInput />
                    </SimpleFormIterator>
                </SelectArrayInput>
            }

        </SimpleForm>
    )

}
