import React from 'react'

import {
    SimpleForm,
    TextInput,
    required,
} from 'react-admin'

export const ModelForm = (props) => {

    return (
        <SimpleForm {...props}>
            <TextInput source="name" label="Name" validate={[required()]} />
        </SimpleForm>
    )

}
