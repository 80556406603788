import React from 'react'
import { FormLabel, Typography } from '@material-ui/core'

export const MainAlgos = props => {
  const {
    record: { mainPoolsByAlgo },
  } = props

  const algoKeys = Object.keys(mainPoolsByAlgo)

  return algoKeys.map(algo => (
    <div>
      <FormLabel style={{ fontSize: 12 }} component='span' variant='body2'>
        Main Pool ({algo})
      </FormLabel>
      <Typography style={{ margin: '8px 0' }} variant='body2'>
        {mainPoolsByAlgo?.[algo]?.name || 'Default'}
      </Typography>
    </div>
  ))
}
