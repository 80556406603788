import React from 'react';
import {Edit} from 'react-admin';
import {ModelForm} from './_Form';

export const EditModel = (props) => (
  <Edit {...props} undoable={false} title="Edit">
    <ModelForm {...props}/>
  </Edit>
);

