import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    Filter,
    TextInput,
    FunctionField,
    DeleteButton,
} from 'react-admin';

export const ListModels = props => (
    <List bulkActionButtons={false} filters={<ModelFilter />} title="Users" exporter={false} {...props}>
        <Datagrid>
            <FunctionField source="id" render={r => r.originId} label="ID"/>
            <TextField source="name" label="Name" />
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

const ModelFilter = (props) => (
    <Filter {...props} >
        <TextInput alwaysOn label="Name" source="name" />
    </Filter>
);

