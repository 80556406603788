import React from 'react'
import { Create } from 'react-admin'
import { CreateBuildConfigForm } from './_CreateForm'

const transform = data => {
  data.buildType = `/api/build_types/${data.buildType}`
  data.selectedOption = `/api/config_param_options/${data.selectedOption}`
  data.company = `/api/companies/${data.company}`
  if (data.modelGroup) {
    data.modelGroup = `/api/model_groups/${data.modelGroup}`
  } else {
    data.model = data.model ? `/api/models/${data.model}` : null
  }
  return data
}

export const CreateBuildConfig = props => (
  <Create {...props} title='Create' resource='param_values/set' transform={transform}>
    <CreateBuildConfigForm {...props} />
  </Create>
)
