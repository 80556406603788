import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  BooleanField,
  DeleteButton,
  Filter,
  TextInput,
  ReferenceField,
  FunctionField,
} from 'react-admin'

export const ListConfigs = props => {
  const companyId = localStorage.getItem('company_id')

  return (
    <List bulkActionButtons={false} filters={<ConfFilter />} title='Configurations' exporter={false} {...props}>
      <Datagrid>
        <FunctionField source='id' label='ID' render={r => r.originId} />
        <TextField source='name' label='Name' />
        {!companyId && (
          <ReferenceField source="company['@id']" label='Company' reference='companies'>
            <TextField source='name' />
          </ReferenceField>
        )}
        <BooleanField source='hideAllPools' label='All Pools Hidden' />
        <ShowButton />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  )
}

const ConfFilter = props => (
  <Filter {...props}>
    <TextInput alwaysOn label='Name' source='name' />
    <TextInput alwaysOn source='company.name' label='Company' />
  </Filter>
)
