import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    EmailField,
    ArrayField,
    SingleFieldList,
    FunctionField,
    ReferenceField,
} from 'react-admin';

import { UserRoles } from './ListUser'

export const ShowUser = (props) => (
    <Show {...props} title={'User'}>
        <SimpleShowLayout>
            <TextField source="originId" label="ID" />
            <EmailField source="email" label="Email" />
            <TextField source="username" label="Name" />
            <ReferenceField label="Company" source="company['@id']" reference="companies">
                <TextField source="name" />
            </ReferenceField>
            <ArrayField source="roles" label="Roles">
                <SingleFieldList linkType={false}>
                    <FunctionField render={role => (UserRoles[role] || role) + ', '} />
                </SingleFieldList>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);



