import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    ShowButton,
    EditButton,
    Filter,
    TextInput,
    FunctionField,
    ReferenceField,
    DeleteButton,
} from 'react-admin';

export const ListUser = props => (
    <List bulkActionButtons={false} filters={<UserFilter />} title="Users" exporter={false} {...props}>
        <Datagrid>
            <FunctionField source="id" render={r => r.originId} label="ID"/>
            <EmailField source="email" label="Email" />
            <TextField source="username" label="Name" />
            <ReferenceField label="Company" source="company['@id']" reference="companies" sortBy="company.name">
                <TextField source="name" />
            </ReferenceField>
            <FunctionField source="roles" render={data => data.roles.map(role => UserRoles[role]).join(', ')} sortable={false}/>
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

const UserFilter = (props) => (
    <Filter {...props} >
        <TextInput alwaysOn label="Name" source="username" />
        <TextInput alwaysOn label="Email" source="email" />
        <TextInput alwaysOn source="company.name" label="Company"/>
    </Filter>
);

export const UserRoles = {
    'ROLE_USER': 'User',
    'ROLE_SUPER_ADMIN': 'ROOT',
    'ROLE_ADMIN': 'Administation',
}

export const GenerateChoices = (array,idIsInt = false) => {
    let choices = []
    for (let [k, v] of Object.entries(array)){
        if(v !== 'ROOT')
            choices.push({ id: idIsInt? +k: k, name: v })
    }

    return choices
}

