import React, { useEffect, useState } from 'react'
import { SimpleForm, SelectInput, ReferenceInput, required, AutocompleteInput } from 'react-admin'
import { getTokens } from '../utils/getTokens'
import { customRequest } from '../utils/customRequest'
import { COMPANIES_REQUEST_TARGET } from '../../constants/companies'
import { BASE_TARGET } from '../../constants'

export const CreateBuildConfigForm = props => {
  const [options, setOptions] = useState([])
  const [models, setModels] = useState([])
  const [modelGroups, setModelGroups] = useState([])
  const [companies, setCompanies] = useState([])

  const { accessToken } = getTokens()

  useEffect(() => {
    const companiesRequest = async () => {
      const { response } = await customRequest(COMPANIES_REQUEST_TARGET, 'GET', {
        page: 1,
        limit: 100,
      })
      setCompanies(response['hydra:member'])
    }

    companiesRequest()
  }, [])

  const handleParamChange = async iri => {
    setModelGroups([])
    setModels([])

    const { response } = await customRequest(BASE_TARGET + iri, 'GET')

    setOptions(response.options)
  }

  const handleOptionChange = async id => {
    setModelGroups([])
    setModels([])
    const iri = `/api/config_param_options/${id}`

    const { response } = await customRequest(BASE_TARGET + iri, 'GET')

    setModels(await getItems(response.models))
    setModelGroups(await getItems(response.modelGroups))
  }

  const getItems = async arr => {
    const items = []

    for (let elem of arr) {
      const response = await fetch(`${BASE_TARGET}${elem['@id']}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const data = await response.json()
      if (!data.id) {
        data.id = data['@id']?.match(/\d+/)[0]
      }
      items.push(data)
    }
    return items
  }

  const defaultFormValues = {
    buildType: null,
    company: null,
    model: null,
    parameter: null,
    selectedOption: null,
  }

  return (
    <SimpleForm {...props} defaultFormValues={defaultFormValues} redirect='list'>
      <AutocompleteInput
        source='company'
        optionText='name'
        optionValue='id'
        translateChoice={false}
        choices={companies}
        validate={[required()]}
      />
      <ReferenceInput source='parameter' label='Parameter' reference='config_param_definitions'>
        <SelectInput optionName='name' inputProps={{ onChange: e => handleParamChange(e.target.value) }} />
      </ReferenceInput>
      <SelectInput
        source='selectedOption'
        allowEmpty={!options.length}
        emptyText='Select parameter first'
        choices={options}
        optionText='name'
        inputProps={{ onChange: e => handleOptionChange(e.target.value) }}
      />
      {!!models.length && <SelectInput source='model' choices={models} />}
      {!!modelGroups.length && <SelectInput source='modelGroup' choices={modelGroups} />}
    </SimpleForm>
  )
}
