import React from 'react';
import transform from '../utils/transform'
import {Create} from 'react-admin';
import {UserForm} from './_Form';

export const CreateUser = (props) => (
  <Create {...props} undoable={false} transform={transform}>
    <UserForm {...props}/>
  </Create>
);

