import React from 'react';
import transform from '../utils/transform'
import {Edit} from 'react-admin';
import {UserForm} from './_Form';

export const EditUser = (props) => (
  <Edit {...props} undoable={false} title={'Edit'} transform={transform}>
    <UserForm {...props}/>
  </Edit>
);


