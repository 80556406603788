import React from 'react'
import { Edit } from 'react-admin'
import { BuildConfigForm } from './_EditForm'

export const EditBuildConfig = props => {
  return (
    <Edit {...props} title='Edit'>
      <BuildConfigForm {...props} />
    </Edit>
  )
}
