import { getTokens } from './getTokens'

export const customRequest = async (url, method = 'GET', params, body) => {
  const { accessToken } = getTokens()

  const defaultHeaders = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/ld+json',
  }

  const response = await fetch(url + '?' + new URLSearchParams(params) || '', {
    headers: defaultHeaders,
    method: method,
    ...(body && { body: JSON.stringify(body) }),
  })
    .then(res => res.json())
    .catch(error => {
      throw new Error(error)
    })

  return { response }
}
