import React from 'react'
import transform from '../utils/transform'
import { Create } from 'react-admin'
import { ConfigForm } from './_Form'

export const CreateConfiguration = (props) => (
  <Create {...props} undoable={false} transform={transform}>
    <ConfigForm {...props} />
  </Create>
)
