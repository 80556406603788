import React from 'react'
import { makeStyles } from '@material-ui/core'
import DownloadFile from './DownloadButton'
import { useDataProvider } from 'react-admin'
import { useState } from 'react'
import classnames from 'classnames'

const useStyles = makeStyles({
  buttonWrapper: {
    '& button': {
      cursor: 'pointer',
      border: 'none',
      borderRadius: '5px',
      boxShadow: '10px 10px 30px rgba(0, 0, 0, 0.11)',
      padding: '3px',
    },
  },
  downloadFile: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 8,
    '& button': {
      backgroundColor: '#CAF5CC',
    },
  },
  downloadLog: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 8,
    '& button': {
      backgroundColor: '#FFC5C5',
      marginLeft: '3px',
    },
  },
  chip: { margin: 4 },
})

const SimpleChipField = props => {
  const { record } = props
  const dataProvider = useDataProvider()
  const [builds, setBuilds] = useState([])

  const fetchFile = React.useCallback(async () => {
    if (record?.builds?.length) {
      let records = []
      await Promise.all(
        record.builds.map(async build => {
          const buildRecord = await dataProvider.getOne('models', {
            id: build,
          })
          const buildLogFile =
            buildRecord.data.buildLog != null &&
            new File([buildRecord.data.buildLog], `build${buildRecord.data.originId}Log.txt`, {
              type: 'text/plain',
            })

          const model = await dataProvider.getOne('models', {
            id: buildRecord.data.model['@id'],
          })
          records.push({
            file: buildRecord.data.file?.path,
            logFile: buildLogFile,
            logFileName: `build${buildRecord.data.originId}Log.txt`,
            filename: buildRecord.data.file?.name.split('_')[0],
            model: model.data.name,
            company: record.configuration.company?.name,
            configuration: record.configuration?.name,
          })
        })
      )
      setBuilds(records)
    }
  }, [])

  React.useEffect(() => {
    fetchFile()
  }, [record])
  const classes = useStyles()
  function downloadLog(file) {
    const link = document.createElement('a')
    const url = URL.createObjectURL(file)

    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
  return builds
    ? builds.map(build =>
        build.file ? (
          <span className={classnames(classes.downloadFile, classes.buttonWrapper)} key={build.id}>
            <DownloadFile build={build} />
          </span>
        ) : (
          build.logFile && (
            <span className={classnames(classes.downloadLog, classes.buttonWrapper)}>
              <button onClick={() => downloadLog(build.logFile)}>{build.logFileName}</button>
            </span>
          )
        )
      )
    : null
}

SimpleChipField.defaultProps = {
  addLabel: true,
}

export default SimpleChipField
