import React, { useState } from 'react'
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  Toolbar,
  useRedirect,
  useNotify,
  required,
  AutocompleteInput,
} from 'react-admin'
import { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import ContentSave from '@material-ui/icons/Save'
import { CustomLoading } from '../Common'
import { customRequest } from '../utils/customRequest'
import { COMPANIES_REQUEST_TARGET } from '../../constants/companies'
import { CONFIG_PARAM_OPTION_REQUEST_TARGET } from '../../constants/configParamOptions'
import { SET_PARAM_VALUES_TARGET } from '../../constants/paramValues'

const CustomToolbar = props => {
  const [disabled, setDisabled] = useState(false)
  const redirect = useRedirect()
  const notify = useNotify()
  const { record, selectedOption, selectedCompany } = props
  const { parameter, model, modelGroup } = record

  const handleSave = async () => {
    setDisabled(true)

    const requestBody = {
      parameter: parameter['@id'],
      company: `/api/companies/${selectedCompany}`,
      model: model?.['@id'] || undefined,
      modelGroup: modelGroup?.['@id'] || undefined,
      selectedOption: `/api/config_param_options/${selectedOption}`,
      overrideModels: true,
    }

    // proxy connection here
    const response = await customRequest(SET_PARAM_VALUES_TARGET, 'POST', {}, requestBody)

    if (response) {
      notify('Element saved')
      redirect('/param_values')
    }
  }

  return (
    <Toolbar {...props}>
      <Button disabled={disabled || !selectedOption} variant='contained' color='primary' onClick={handleSave}>
        <ContentSave style={{ marginRight: 10 }} />
        Save
      </Button>
    </Toolbar>
  )
}

export const BuildConfigForm = props => {
  const { record } = props
  const [options, setOptions] = useState([])
  const [currentOption, setCurrentOption] = useState(record.selectedOption.id)
  const [selectedCompany, setSelectedCompany] = useState(record.company.id)
  const [companies, setCompanies] = useState()

  useEffect(() => {
    const optionsRequest = async () => {
      if (record) {
        const parameter = record.parameter?.id
        const model = record.model?.id
        let modelGroup

        if (!model) {
          modelGroup = record.modelGroup?.id
        }

        const { response } = await customRequest(CONFIG_PARAM_OPTION_REQUEST_TARGET, 'GET', {
          ...(parameter && { 'parameter.id': parameter }),
          ...(model && { 'models.id': model }),
          ...(modelGroup && { 'modelGroups.id': modelGroup }),
        })

        setOptions(response['hydra:member'])
      }
    }

    const companiesRequest = async () => {
      const { response } = await customRequest(COMPANIES_REQUEST_TARGET, 'GET', {
        page: 1,
        limit: 100,
      })

      setCompanies(response['hydra:member'])
    }

    optionsRequest()
    companiesRequest()
  }, [])

  const format = r => (r && r['@id'] ? r['@id'] : r)

  return (
    <SimpleForm {...props} toolbar={<CustomToolbar selectedOption={currentOption} selectedCompany={selectedCompany} />}>
      <AutocompleteInput
        label='Company'
        source='company.id'
        optionText='name'
        optionValue='id'
        translateChoice={false}
        choices={companies}
        validate={[required()]}
        onChange={setSelectedCompany}
        value={selectedCompany}
      />
      <ReferenceInput source='parameter' format={format} label='Parameter' reference='config_param_definitions'>
        <SelectInput disabled optionName='name' />
      </ReferenceInput>
      {!!options.length ? (
        <SelectInput
          source='selectedOption'
          format={v => v.id}
          choices={options}
          optionText='name'
          inputProps={{
            onChange: e => setCurrentOption(e.target.value),
            value: currentOption,
          }}
        />
      ) : (
        <CustomLoading />
      )}
      {record.model && (
        <ReferenceInput source='model' format={format} label='Model' reference='companies'>
          <SelectInput disabled optionName='name' />
        </ReferenceInput>
      )}
      {record.modelGroup && (
        <ReferenceInput source='modelGroup' format={format} label='Model group' reference='companies'>
          <SelectInput disabled optionName='name' />
        </ReferenceInput>
      )}
    </SimpleForm>
  )
}
