import React, { useEffect, useState } from 'react'

import {
  SimpleForm,
  ArrayInput,
  TextInput,
  required,
  NumberInput,
  SimpleFormIterator,
  BooleanInput,
  ReferenceInput,
  SelectArrayInput,
  usePermissions,
  useDataProvider,
  AutocompleteInput,
} from 'react-admin'

const iriPerefix = '/api/companies/'

export const ConfigForm = props => {
  const { permissions } = usePermissions()
  const { record } = props
  const [models, setModels] = useState()
  const dataProvider = useDataProvider()
  const companyId = localStorage.getItem('company_id')

  useEffect(() => {
    if (
      permissions &&
      !permissions?.includes('ROLE_ADMIN') &&
      !permissions?.includes('ROLE_SUPER_ADMIN') &&
      companyId
    ) {
      fetchModels(iriPerefix + companyId)
    }
  }, [companyId, permissions])

  useEffect(() => {
    if (record?.company) fetchModels(record.company['@id'])
  }, [record])

  const fetchModels = async value => {
    const company = await dataProvider.getOne('companies', { id: value })
    const models = await dataProvider.getMany('models', {
      ids: company.data.allowedModels,
    })

    if (models.data.length) {
      setModels(models.data)
    } else {
      setModels([{ name: 'Choose company with available models' }])
    }
  }

  const formatModels = selected => {
    const modelIds = models?.map(model => model.id)
    return selected?.filter(model => modelIds?.includes(model))
  }

  const feeValidation = value => {
    if (value === null) {
      return 'Required'
    }
    if (value < 0) {
      return 'Must be over 0'
    }
    return []
  }

  return (
    <SimpleForm {...props}>
      {(permissions?.includes('ROLE_ADMIN') || permissions?.includes('ROLE_SUPER_ADMIN')) && (
        <ReferenceInput
          source='company.@id'
          label='Company'
          reference='companies'
          validate={[required()]}
          onChange={fetchModels}
        >
          <AutocompleteInput optionText={value => value.name || ''} translateChoice={false} />
        </ReferenceInput>
      )}
      <TextInput source='name' label='Name' validate={[required()]} />
      <SelectArrayInput
        source='models'
        choices={models}
        format={formatModels}
        optionText='name'
        validate={[required()]}
      />
      <ArrayInput source='pools' label='Pools'>
        <SimpleFormIterator>
          <TextInput source='url' label='Url' validate={[required()]} />
          <TextInput source='worker' label='Worker' validate={[required()]} />
          <TextInput source='password' label='Password' validate={[required()]} />
          <NumberInput
            source='fee'
            label='Fee'
            inputProps={{
              step: 0.5,
              min: 0.0,
              max: 10000000.0,
            }}
            validate={feeValidation}
          />
          <BooleanInput label='Hidden' source='hidden' />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput label='Hide All Pools' source='hideAllPools' />
    </SimpleForm>
  )
}
