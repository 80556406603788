import React from 'react';
import transform from '../utils/transform';
import {Edit} from 'react-admin';
import {ConfigForm} from './_Form';

export const EditConfiguration = (props) => (
  <Edit {...props} undoable={false} title={'Edit'} transform={transform}>
    <ConfigForm {...props}/>
  </Edit>
);

