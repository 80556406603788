import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
} from 'react-admin';

export const ShowModel = (props) => (
    <Show {...props} title={'Model'}>
        <SimpleShowLayout>
            <TextField source="originId" label="ID" />
            <TextField source="name" label="Name" />
        </SimpleShowLayout>
    </Show>
);



