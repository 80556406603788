import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    FunctionField,
    Filter,
    TextInput
} from 'react-admin';

import DeleteWithConfirmButton from '../Common/DeleteWithConfirmButton'

export const ListCompanies = props => (
    <List bulkActionButtons={false} title="Companies" filters={<CompanyFilter/>} exporter={false} {...props}>
        <Datagrid>
            <FunctionField source="id" render={r => r.originId} label="ID"/>
            <TextField source="name" label="Company" />
            <TextField source="prefix" label="Prefix" />
            <ShowButton />
            <EditButton />
            <DeleteWithConfirmButton undoable={false} textConfirm={'Delete company'} subTextConfirm={'If you delete company, you also delete all users of this company. Are you sure?'}/>
        </Datagrid>
    </List>
);

const CompanyFilter = (props) => (
    <Filter {...props} >
        <TextInput alwaysOn label="Name" source="name" />
        <TextInput alwaysOn label="Prefix" source="prefix" />
    </Filter>
);